
export default {
  name: 'TextBlockComponent',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    // based on the button precence, we modify the aspect of the TextBlock content
    // hasPadding() {
    //   return this.data.button.link ? 'py-8 lg:py-11' : 'pt-8 lg:pt-11';
    // },
    // buttonHasPadding() {
    //   return this.data.button.link ? 'mt-6 lg:mt-6' : 'mt-6 lg:mt-6';
    // }
  }
};
